<template>
  <div>
    <v-card>
      <v-toolbar>
        <v-card-title>Engine presets</v-card-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="
            $can('create', 'engine_presets') || $can('create', 'Superadmin')
          "
          :color="Pallette.actionButtons.newItem"
          fab
          dark
          absolute
          bottom
          right
          :small="!$vuetify.breakpoint.smAndUp"
          @click.stop="createPreset()"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <EnginePresetDialogCrud
          :activePreset="activePreset"
          :dialog.sync="dialog"
          :editedIndex="editedIndex"
          :gotoStepTab="gotoStepTab"
          @preset-data-saved="fetchTableData"
          ref="refDialogPreset"
        ></EnginePresetDialogCrud>
      </v-toolbar>

      <v-card-text>
        <DataTableExtended
          ref="table"
          :expanded="presets"
          :calculate-widths="true"
          :headers="headers"
          :items="presets"
          :server-items-length="presets_count"
          class="elevation-0 border"
          :loading="showTableLoader"
          :loading-text="showTableLoader ? 'Loading…' : 'No data available'"
          v-on:init-table-data="fetchTableData"
          :customSearch="filterSearch"
          @update-search="
            (newS) => {
              this.filterSearch = newS
            }
          "
          keep="_key,name,type,status,engine_keys,user_key"
        >
          <template v-slot:top="{ options, updateOptions }">
            <v-row class="px-3">
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  v-model="filterSearch"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  :style="$vuetify.breakpoint.smAndUp ? 'max-width: 300px' : ''"
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>
            <SortMobile
              :headers="headers"
              :options="options"
              @update:options="updateOptions"
              v-if="!$vuetify.breakpoint.smAndUp"
            />
          </template>
          <template v-slot:[`item.name`]="{ item }">
            {{ item.name }}
          </template>
          <template v-slot:[`item.type`]="{ item }">
            {{ item.type }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <span
              :class="
                item.status === 'active'
                  ? Pallette.statusText.active
                  : Pallette.statusText.notActive
              "
            >
              <span class="overline">
                <i class="fa fa-circle mx-2"></i>
              </span>
              <span class="font-weight-regular">{{ item.status }}</span>
            </span>
          </template>
          <template v-slot:[`item.menu`]="{ item }">
            <v-menu transition="slide-y-transition" left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  dark
                  small
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  :color="Pallette.actionButtons.menu"
                  class="clear-padding"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  @click="editPreset(item, 1)"
                  v-if="
                    $can('update', 'engine_presets') ||
                    $can('update', 'Superadmin')
                  "
                >
                  <v-list-item-content>
                    <v-list-item-title>Engines list</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-badge
                      bordered
                      overlap
                      :color="Pallette.actionButtons.editBadge"
                      :content="item.engine_keys.length"
                    >
                      <v-icon :color="Pallette.actionButtons.edit">
                        desktop_windows
                      </v-icon>
                    </v-badge>
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item
                  @click="editPreset(item, 0)"
                  v-if="
                    item.user_key &&
                    ($can('update', 'engine_presets') ||
                      $can('update', 'Superadmin'))
                  "
                >
                  <v-list-item-content>
                    <v-list-item-title>Edit preset</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon :color="Pallette.actionButtons.edit">
                      mdi-pencil
                    </v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item
                  @click="deletePreset(item)"
                  v-if="
                    $can('delete', 'engine_presets') ||
                    $can('delete', 'Superadmin')
                  "
                >
                  <v-list-item-content>
                    <v-list-item-title>Delete preset</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon :color="Pallette.actionButtons.delete">
                      mdi-delete
                    </v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template
            v-slot:expanded-item="{ headers, item }"
            v-if="
              !$vuetify.breakpoint.smAndUp &&
              ($can('update', 'engine_presets') ||
                $can('delete', 'engine_presets') ||
                $can('update', 'Superadmin'))
            "
          >
            <td :colspan="headers.length">
              <v-row align="center" justify="space-around">
                <VerticalButton
                  @click.native="editPreset(item, 1)"
                  v-if="
                    $can('update', 'engine_presets') ||
                    $can('update', 'Superadmin')
                  "
                  icon="desktop_windows"
                  text="Engines list"
                  :color="Pallette.actionButtons.edit"
                />
                <VerticalButton
                  @click.native="editPreset(item, 0)"
                  v-if="
                    item.user_key &&
                    ($can('update', 'engine_presets') ||
                      $can('update', 'Superadmin'))
                  "
                  icon="mdi-pencil"
                  text="Edit preset"
                  :color="Pallette.actionButtons.edit"
                />
                <VerticalButton
                  @click.native="deletePreset(item)"
                  v-if="
                    $can('delete', 'engine_presets') ||
                    $can('delete', 'Superadmin')
                  "
                  icon="mdi-delete"
                  text="Delete preset"
                  :color="Pallette.actionButtons.delete"
                />
              </v-row>
            </td>
          </template>
        </DataTableExtended>
      </v-card-text>
    </v-card>
    <confirm ref="confirmDeletePreset"></confirm>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import EnginePresetDialogCrud from "./EnginePresetDialogCrud"
import Confirm from "@/components/Confirm"
import PresetsMixins from "@/mixins/presets.js"
import VerticalButton from "@/components/buttons/VerticalButton"
import DataTableExtended from "@/components/table/DataTableExtended"
import SortMobile from "@/components/table/SortMobile"

export default {
  components: {
    EnginePresetDialogCrud,
    Confirm,
    VerticalButton,
    DataTableExtended,
    SortMobile,
  },

  mixins: [PresetsMixins],

  data() {
    return {
      dialog: false,
      selected: [],
      headers: [
        {
          text: "Name",
          align: "left",
          value: "name",
        },
        {
          text: "Type",
          align: "left",
          value: "type",
        },
        {
          text: "Status",
          align: "center",
          value: "status",
        },
        {
          text: "",
          value: "menu",
          align: "center",
          sortable: false,
          class: "th-clear-padding",
          width: 50,
        },
      ],
      presets: [],
      presets_count: 0,
      editedIndex: -1,
      activePreset: {
        name: "",
        type: "studio_displays",
        engine_items: [],
        engine_studios: [],
        status: "active",
      },
      default_preset: {
        name: "",
        type: "studio_displays",
        engine_items: [],
        engine_studios: [],
        status: "active",
      },
      gotoStepTab: null,
      filterSearch: "",
    }
  },

  mounted: function () {
    if (!this.$can("read", "engine_presets")) {
      if (!this.$can("read", "Superadmin"))
        this.$router.push({ name: "home" }).catch((err) => {
          // catch route push
        })
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    filterSearch() {
      this.fetchTableData()
    },
  },

  methods: {
    toggleAll() {
      if (this.selected.length) this.selected = []
      else this.selected = this.presets.slice()
    },
    fetchTableData: function () {
      let self = this
      let getParams = self.$refs.table.getTableServerParams()
      if (self.filterSearch !== "") getParams.search = self.filterSearch
      self.$store.dispatch("saveTableLoaderAction", true)
      self.$store
        .dispatch("presetsEnginesGetList", { params: getParams })
        .then(function (response) {
          self.presets = response?.data?.data?.result || []
          self.presets_count = response?.data?.data?.full_count || 0
          self.$store.dispatch("saveTableLoaderAction", false)
        })
    },
    createPreset() {
      this.gotoStepTab = 0
      this.dialog = true
      this.activePreset = Object.assign({}, this.default_preset)
      this.editedIndex = -1
    },
    editPreset(item, gotoStepTab) {
      this.getSinglePresetInfo(item._key)
      this.editedIndex = item._key
      this.gotoStepTab = gotoStepTab
      this.dialog = true
    },
    deletePreset(item) {
      let app = this
      app.$refs.confirmDeletePreset
        .open("Delete", "Are you sure you want to delete this preset?", {
          color: "red darken-4",
        })
        .then((confirm) => {
          if (confirm) {
            app.$store.dispatch("saveTableLoaderAction", true)
            app.$store
              .dispatch("presetsEnginesDelete", item)
              .then(({ data }) => {
                app.$emit("preset-data-saved")
                app.fetchTableData()
                app.$store.dispatch("saveTableLoaderAction", false)
              })
          }
        })
    },
    close() {
      this.dialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.default_preset)
        this.editedIndex = -1
      }, 300)
    },
  },

  computed: {
    ...mapGetters({
      showTableLoader: "getShowTableLoader",
      Pallette: "get_Pallette",
    }),
  },
}
</script>

<style lang="scss" scoped>
.v-chip {
  width: 5em;
  justify-content: center;
}

.cell-actions {
  width: 120px;
}
</style>
