<template>
  <v-dialog v-model="dialog" persistent max-width="1000px">
    <v-card>
      <v-toolbar extended>
        <span class="headline" v-html="getTitle()"></span>
        <v-spacer></v-spacer>
        <template v-slot:extension>
          <v-tabs v-model="activeActionTab" fixed-tabs>
            <v-tabs-slider color="yellow"></v-tabs-slider>
            <v-tab
              :class="[
                errors.has('form-presets.name') ? 'error-tab-title' : '',
              ]"
            >
              <v-icon left>mdi-settings-box</v-icon>
              Main settings
            </v-tab>
            <v-tab
              :class="[
                errors.has('form-presets.engine_items')
                  ? 'error-tab-title'
                  : '',
              ]"
            >
              <v-icon left>mdi-monitor-multiple</v-icon>
              Engines settings
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
      <v-card-text class="pa-4">
        <v-form data-vv-scope="form-presets">
          <v-tabs-items v-model="activeActionTab" touchless>
            <v-tab-item :transition="false" :reverse-transition="false">
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      outlined
                      ref="name"
                      v-model="activePreset.name"
                      v-validate="'required|max:50'"
                      :error-messages="errors.collect('form-presets.name')"
                      label="Name"
                      placeholder=""
                      data-vv-name="name"
                      required
                      data-vv-validate-on="change"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-radio-group
                      v-model="activePreset.type"
                      :mandatory="true"
                      box
                      class="mb-4 mt-0"
                    >
                      <div slot="label">Type:</div>
                      <v-radio
                        label="Default"
                        value="default"
                        color="indigo"
                        class="mt-4"
                      ></v-radio>
                      <v-radio
                        label="Studio & Displays"
                        value="studio_displays"
                        color="indigo"
                      ></v-radio>
                    </v-radio-group>
                    <v-radio-group
                      v-model="activePreset.status"
                      :mandatory="true"
                      box
                      class="mb-4 mt-0"
                    >
                      <div slot="label">Status:</div>
                      <v-radio
                        label="Active"
                        value="active"
                        color="indigo"
                        class="mt-4"
                      ></v-radio>
                      <v-radio label="Off" value="off" color="indigo"></v-radio>
                      <!--<v-radio label="Archived" value="archived" color="indigo"></v-radio>-->
                      <!--<v-radio label="Scheduled" value="scheduled" color="indigo"></v-radio>-->
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item :transition="false" :reverse-transition="false">
              <v-alert
                :value="true"
                type="error"
                v-if="errors.has('form-presets.engine_keys')"
              >
                <!--<ul>-->
                <!--<li v-for="error in errors.collect('form-presets.engine_items')">{{ error }}</li>-->
                <!--</ul>-->
                {{ errors.first("form-presets.engine_keys") }}
              </v-alert>
              <v-alert
                :value="true"
                type="error"
                v-if="errors.has('form-presets.engine_studio_key')"
              >
                {{ errors.first("form-presets.engine_studio_key") }}
              </v-alert>
              <!-- {{getEnginesCompanyList[0]}} -->
              <!-- {{selectedEngines}} -->
              <!-- {{clear_intersection_presets}} -->
              <!-- {{selectedStudio}} -->
              <!-- {{headers}} -->
              <!-- v-model="selectedEngines" -->
              <v-data-table
                ref="tableEngines"
                :headers="headers"
                :items="getEnginesCompanyList"
                class="elevation-0 border mt-4"
                :search="search"
                :loading="loading"
                :options.sync="options"
                :footer-props="footerOptions"
                item-key="_key"
                :single-select="false"
                :calculate-widths="true"
                :no-data-text="
                  showTableLoader ? 'Loading…' : 'No data available'
                "
                :items-per-page.sync="options.itemsPerPage"
                :server-items-length="enginesCompanyListCount"
                v-model="selectedEngines"
                show-select
                @update:options="fetchTableData(options)"
              >
                <template v-slot:[`item.name`]="{ item }">
                  <div>
                    {{ item.name }}
                    <!-- {{ item._key }} -->
                  </div>
                </template>
                <template v-slot:[`item.serial_number`]="{ item }">
                  <div>
                    {{ item.serial_number }}
                  </div>
                </template>
                <template v-slot:[`item._active_preset`]="{ item }">
                  <template
                    v-if="item._active_preset && item._active_preset.name"
                  >
                    {{ item._active_preset.name }}
                  </template>
                </template>
                <template v-slot:[`item.company_name`]="{ item }">
                  <div>
                    {{ item.company_name }}
                  </div>
                </template>
                <template v-slot:[`item.radio`]="{ item }">
                  <template>
                    <v-radio-group
                      v-model="selectedStudio"
                      class="radio-preset-studio ma-0"
                      v-if="isSelected(item._key)"
                      :key="'radio' + item._key"
                    >
                      <v-radio
                        class="dt-radiengine_studioso"
                        :value="item._key"
                        :key="item._key"
                      />
                    </v-radio-group>
                  </template>
                </template>
              </v-data-table>
            </v-tab-item>
          </v-tabs-items>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <!--<v-btn flat @click.native="stepper = 2">Back</v-btn>-->
        <v-btn color="error" text @click.native="closeDialog()">Close</v-btn>
        <v-btn
          text
          v-if="checkShowSaveBtn()"
          color="success"
          @click.stop="save()"
          >Save</v-btn
        >
        <v-btn
          text
          v-if="checkShowNextBtn()"
          color="primary"
          @click="activeActionTab = 1"
          >Next</v-btn
        >
      </v-card-actions>
    </v-card>
    <confirm ref="confirmRemovePresetsIntersections"></confirm>
  </v-dialog>
</template>

<script>
import { filter, map, cloneDeep, includes } from "lodash"
import { format, subDays, addDays } from "date-fns"
import { mapGetters } from "vuex"
import PresetsMixins from "@/mixins/presets"

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    activePreset: {
      type: Object,
      default() {
        return {}
      },
    },
    editedIndex: {
      type: [Number, String],
      default: -1,
    },
    gotoStepTab: {
      type: Number,
      default: null,
    },
  },

  mixins: [PresetsMixins],

  data() {
    return {
      range: [],
      dateRangeOptions: {
        startDate: format(subDays(new Date(), 7), "yyyy-mm-dd"),
        endDate: format(new Date(), "yyyy-mm-dd"),
        format: "MM/DD/YYYY",
        presets: [
          {
            label: "Today",
            range: [
              format(new Date(), "yyyy-mm-dd"),
              format(new Date(), "yyyy-mm-dd"),
            ],
          },
          {
            label: "Yesterday",
            range: [
              format(subDays(new Date(), 1), "yyyy-mm-dd"),
              format(subDays(new Date(), 1), "yyyy-mm-dd"),
            ],
          },
          {
            label: "Last 30 Days",
            range: [
              format(subDays(new Date(), 30), "yyyy-mm-dd"),
              format(subDays(new Date(), 1), "yyyy-mm-dd"),
            ],
          },
        ],
      },
      dateRangeOptionsWithMinDate: {
        startDate: format(subDays(new Date(), 7), "yyyy-mm-dd"),
        endDate: format(new Date(), "yyyy-mm-dd"),
        minDate: format(subDays(new Date(), 15), "yyyy-mm-dd"),
        maxDate: format(addDays(new Date(), 20), "yyyy-mm-dd"),
        format: "MM/DD/YYYY",
        presets: [
          {
            label: "Today",
            range: [
              format(new Date(), "yyyy-mm-dd"),
              format(new Date(), "yyyy-mm-dd"),
            ],
          },
          {
            label: "Yesterday",
            range: [
              format(subDays(new Date(), 1), "yyyy-mm-dd"),
              format(subDays(new Date(), 1), "yyyy-mm-dd"),
            ],
          },
          {
            label: "Last 30 Days",
            range: [
              format(subDays(new Date(), 30), "yyyy-mm-dd"),
              format(subDays(new Date(), 1), "yyyy-mm-dd"),
            ],
          },
        ],
      },
      clear_intersection_presets: false,
      search: "",
      loading: false,
      selectedEngines: [],
      selectedStudio: null,
      headers: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Serial number",
          value: "serial_number",
        },
        {
          text: "Presets",
          value: "presets",
        },
        {
          text: "Studio",
          value: "studio",
        },
        {
          text: "Selected",
          value: "radio",
          sortable: false,
        },
      ],
      engines: [],
      activeActionTab: null,
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      footerOptions: {
        "items-per-page-text": "",
        "items-per-page-options": [5, 10, 15, 20, 30],
      },
      serverPagination: {
        p: 1,
        pp: 10,
        sort: "",
        order: "",
      },
    }
  },

  watch: {
    dialog: function (newVal) {
      if (newVal) {
        this.fetchTableData()
        this.activeActionTab = this.gotoStepTab
      }
    },
    activePreset: function (newVal) {
      let engineKeysArr = newVal.engine_keys || []
      this.selectedEngines = filter(this.engines, (v) =>
        engineKeysArr.includes(v._key)
      )
      this.selectedStudio = newVal.engine_studio_key
    },
    selectedEngines: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler(newVal) {
        let selectedEnginesKeys = map(newVal, "_key")
        if (!includes(selectedEnginesKeys, this.selectedStudio)) {
          this.selectedStudio = null
        }
      },
    },
  },

  mounted: function () {
    if (!this.$can("read", "engines")) {
      if (!this.$can("read", "Superadmin"))
        this.$router.push({ name: "home" }).catch((err) => {})
    }
  },

  methods: {
    fetchTableData: function (options) {
      let self = this

      if (options) {
        self.serverPagination.sort =
          options.sortBy && options.sortBy[0] ? options.sortBy[0] : "name"
        self.serverPagination.order =
          options.sortDesc && options.sortDesc[0] ? "DESC" : "ASC"
      }

      let params = {
        p: self.options.page,
        pp: self.options.itemsPerPage,
        // keep: '_key' @todo define wat fields need to keep in response
      }
      if (self.search !== "") params.search = self.search
      if (self.serverPagination.sort !== "")
        params.sort = self.serverPagination.sort
      if (self.serverPagination.order !== "")
        params.order = self.serverPagination.order
      params.show_active_preset = 1

      self.$store.dispatch("enginesCompanyGetList", { params })
    },
    checkShowSaveBtn() {
      return (
        this.editedIndex != -1 ||
        (this.activeActionTab && this.editedIndex == -1)
      )
    },
    checkShowNextBtn() {
      return (
        !this.activeActionTab &&
        this.editedIndex == -1 &&
        this.activePreset.name != ""
      )
    },
    onDateRangeChange(range) {
      this.range = range
    },
    getTitle() {
      return this.editedIndex == -1
        ? "Create preset"
        : `<span class="blue-grey--text text--lighten-4">Edit preset</span> ${this.activePreset.name}`
    },
    isSelected(key) {
      let selectedEnginesKeys = map(cloneDeep(this.selectedEngines), "_key")
      if (selectedEnginesKeys.length === 0) return false
      return includes(selectedEnginesKeys, key)
    },
  },

  computed: {
    ...mapGetters({
      showTableLoader: "getShowTableLoader",
      getEnginesCompanyList: "getEnginesCompanyList",
      enginesCompanyListCount: "enginesCompanyListCount",
    }),
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/vertical-tabs.scss";
</style>

<style lang="scss" scoped>
.dt-header-left {
  text-align: left;
}

.v-tabs--vertical .error-tab-title a {
  color: red !important;
  text-decoration: underline;
}

.dt-radio {
  position: absolute;
  top: 50% !important;
}

.radio-preset-studio {
  position: relative;
  top: 11px;
}
</style>
