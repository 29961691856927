var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',[_c('v-toolbar',[_c('v-card-title',[_vm._v("Engine presets")]),_c('v-spacer'),(
          _vm.$can('create', 'engine_presets') || _vm.$can('create', 'Superadmin')
        )?_c('v-btn',{attrs:{"color":_vm.Pallette.actionButtons.newItem,"fab":"","dark":"","absolute":"","bottom":"","right":"","small":!_vm.$vuetify.breakpoint.smAndUp},on:{"click":function($event){$event.stopPropagation();return _vm.createPreset()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e(),_c('EnginePresetDialogCrud',{ref:"refDialogPreset",attrs:{"activePreset":_vm.activePreset,"dialog":_vm.dialog,"editedIndex":_vm.editedIndex,"gotoStepTab":_vm.gotoStepTab},on:{"update:dialog":function($event){_vm.dialog=$event},"preset-data-saved":_vm.fetchTableData}})],1),_c('v-card-text',[_c('DataTableExtended',{ref:"table",staticClass:"elevation-0 border",attrs:{"expanded":_vm.presets,"calculate-widths":true,"headers":_vm.headers,"items":_vm.presets,"server-items-length":_vm.presets_count,"loading":_vm.showTableLoader,"loading-text":_vm.showTableLoader ? 'Loading…' : 'No data available',"customSearch":_vm.filterSearch,"keep":"_key,name,type,status,engine_keys,user_key"},on:{"init-table-data":_vm.fetchTableData,"update-search":(newS) => {
            this.filterSearch = newS
          }},scopedSlots:_vm._u([{key:"top",fn:function({ options, updateOptions }){return [_c('v-row',{staticClass:"px-3"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{style:(_vm.$vuetify.breakpoint.smAndUp ? 'max-width: 300px' : ''),attrs:{"outlined":"","append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.filterSearch),callback:function ($$v) {_vm.filterSearch=$$v},expression:"filterSearch"}})],1)],1),(!_vm.$vuetify.breakpoint.smAndUp)?_c('SortMobile',{attrs:{"headers":_vm.headers,"options":options},on:{"update:options":updateOptions}}):_vm._e()]}},{key:`item.name`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:`item.type`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.type)+" ")]}},{key:`item.status`,fn:function({ item }){return [_c('span',{class:item.status === 'active'
                ? _vm.Pallette.statusText.active
                : _vm.Pallette.statusText.notActive},[_c('span',{staticClass:"overline"},[_c('i',{staticClass:"fa fa-circle mx-2"})]),_c('span',{staticClass:"font-weight-regular"},[_vm._v(_vm._s(item.status))])])]}},{key:`item.menu`,fn:function({ item }){return [_c('v-menu',{attrs:{"transition":"slide-y-transition","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"clear-padding",attrs:{"fab":"","dark":"","small":"","outlined":"","color":_vm.Pallette.actionButtons.menu}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[(
                  _vm.$can('update', 'engine_presets') ||
                  _vm.$can('update', 'Superadmin')
                )?_c('v-list-item',{on:{"click":function($event){return _vm.editPreset(item, 1)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Engines list")])],1),_c('v-list-item-icon',[_c('v-badge',{attrs:{"bordered":"","overlap":"","color":_vm.Pallette.actionButtons.editBadge,"content":item.engine_keys.length}},[_c('v-icon',{attrs:{"color":_vm.Pallette.actionButtons.edit}},[_vm._v(" desktop_windows ")])],1)],1)],1):_vm._e(),(
                  item.user_key &&
                  (_vm.$can('update', 'engine_presets') ||
                    _vm.$can('update', 'Superadmin'))
                )?_c('v-list-item',{on:{"click":function($event){return _vm.editPreset(item, 0)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Edit preset")])],1),_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":_vm.Pallette.actionButtons.edit}},[_vm._v(" mdi-pencil ")])],1)],1):_vm._e(),(
                  _vm.$can('delete', 'engine_presets') ||
                  _vm.$can('delete', 'Superadmin')
                )?_c('v-list-item',{on:{"click":function($event){return _vm.deletePreset(item)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Delete preset")])],1),_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":_vm.Pallette.actionButtons.delete}},[_vm._v(" mdi-delete ")])],1)],1):_vm._e()],1)],1)]}},(
            !_vm.$vuetify.breakpoint.smAndUp &&
            (_vm.$can('update', 'engine_presets') ||
              _vm.$can('delete', 'engine_presets') ||
              _vm.$can('update', 'Superadmin'))
          )?{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',{attrs:{"align":"center","justify":"space-around"}},[(
                  _vm.$can('update', 'engine_presets') ||
                  _vm.$can('update', 'Superadmin')
                )?_c('VerticalButton',{attrs:{"icon":"desktop_windows","text":"Engines list","color":_vm.Pallette.actionButtons.edit},nativeOn:{"click":function($event){return _vm.editPreset(item, 1)}}}):_vm._e(),(
                  item.user_key &&
                  (_vm.$can('update', 'engine_presets') ||
                    _vm.$can('update', 'Superadmin'))
                )?_c('VerticalButton',{attrs:{"icon":"mdi-pencil","text":"Edit preset","color":_vm.Pallette.actionButtons.edit},nativeOn:{"click":function($event){return _vm.editPreset(item, 0)}}}):_vm._e(),(
                  _vm.$can('delete', 'engine_presets') ||
                  _vm.$can('delete', 'Superadmin')
                )?_c('VerticalButton',{attrs:{"icon":"mdi-delete","text":"Delete preset","color":_vm.Pallette.actionButtons.delete},nativeOn:{"click":function($event){return _vm.deletePreset(item)}}}):_vm._e()],1)],1)]}}:null],null,true)})],1)],1),_c('confirm',{ref:"confirmDeletePreset"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }